<template>
  <v-card
    class="mx-auto"
    tile
    elevation="1"
  >
    <v-list flat>
      <v-list-item-group
        mandatory
        color="blue"
        v-model="selectedItem"
        @change="itemChanged"
      >
        <v-subheader>General</v-subheader>
        <v-list-item :value="forms.profile">
          <v-list-item-title>
            {{ forms.profile.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.profile"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.theme">
          <v-list-item-title>
            {{ forms.theme.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.theme"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-subheader>Fleets</v-subheader>
        <v-list-item :value="forms.fleetAssociations">
          <v-list-item-title>
            {{ forms.fleetAssociations.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.fleetAssociations" :warning="noFleetWarning"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.fleetNetworks">
          <v-list-item-title>
            {{ forms.fleetNetworks.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.fleetNetworks"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-subheader>Passenger App</v-subheader>
        <v-list-item :value="forms.appPreview">
          <v-list-item-title>
            {{ forms.appPreview.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.appPreview"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.appBuildSettings">
          <v-list-item-title>
            {{ forms.appBuildSettings.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.appBuildSettings"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.appSigning">
          <v-list-item-title>
            {{ forms.appSigning.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.appSigning"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.features">
          <v-list-item-title>
            {{ forms.features.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.features"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>

        <v-list-item :value="forms.addressSearchConfiguration">
          <v-list-item-title>
            {{ forms.addressSearchConfiguration.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.addressSearchConfiguration"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.alerts">
          <v-list-item-title>
            {{ forms.alerts.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.alerts"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>

        <v-subheader>Firebase Analytics</v-subheader>
        <v-list-item :value="forms.firebaseAnalyticsConfig">
          <v-list-item-title>
            {{ forms.firebaseAnalyticsConfig.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.firebaseAnalyticsConfig"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.firebaseWebbookerAnalytics">
          <v-list-item-title>
            {{ forms.firebaseWebbookerAnalytics.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.firebaseWebbookerAnalytics"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>

        <v-subheader>System</v-subheader>
        <v-list-item :value="forms.oidcClients">
          <v-list-item-title>
            {{ forms.oidcClients.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.oidcClients"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.paymentConnector">
          <v-list-item-title>
            {{ forms.paymentConnector.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.paymentConnector"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.userConnector">
          <v-list-item-title>
            {{ forms.userConnector.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.userConnector"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.geoConnector">
          <v-list-item-title>
            {{ forms.geoConnector.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.geoConnector"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.notificationsConnector">
          <v-list-item-title>
            {{ forms.notificationsConnector.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.notificationsConnector"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.iosAppStoreConfigConnector">
          <v-list-item-title>
            {{ forms.iosAppStoreConfigConnector.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.iosAppStoreConfigConnector"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-subheader>Business Applications</v-subheader>
        <v-list-item :value="forms.businessSolution">
          <v-list-item-title>
            {{ forms.businessSolution.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.businessSolution"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.customerConsole">
          <v-list-item-title>
            {{ forms.customerConsole.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.customerConsole"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item :value="forms.marketingConsole">
          <v-list-item-title>
            {{ forms.marketingConsole.display }}
          </v-list-item-title>
          <v-list-item-icon>
            <booking-channel-menu-status :remote-state="remoteState" :form="forms.marketingConsole"></booking-channel-menu-status>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import { get } from 'lodash';
import bookingChannelFormResolver from '@/core/bookingChannelFormResolver';
import bookingChannelStepMixin from '@/mixins/bookingChannelStep.mixin';
import BookingChannelMenuStatus from './BookingChannelMenuStatus.vue';

export default {
  name: 'BookingChannelSettingsMenu',
  mixins: [bookingChannelStepMixin],
  components: {
    BookingChannelMenuStatus,
  },
  props: {
    configurationState: {
      type: Object,
    },
    remoteState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  created() {
    this.selectedItem = this.forms.profile;
    this.$emit('itemClick', this.selectedItem);
  },
  methods: {
    itemChanged(formItem) {
      this.$emit('itemClick', formItem);
    },
  },
  computed: {
    forms() {
      return bookingChannelFormResolver.getAllForms();
    },
    noFleetWarning() {
      return !get(this.remoteState, 'bookingChannel.fleets.length');
    },
  },
};
</script>

<style scoped lang="scss">
.theme--light.v-subheader {
  font-weight: 600;
  color: #2371e4;
}
.v-list-item-group .v-list-item--active {
  background-color: rgba(33, 150, 243, 0.1);
}
.v-list-item {
  height: 2rem;
}
</style>
