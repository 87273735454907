import axios from 'axios';
import { Authentication } from '@icabbi/vue-authentication';
import { BookingChannelUtil } from '@/util/bookingChannelUtil';
import _ from 'lodash';

const defaultState = {
  remoteState: {
    bookingChannelRevision: null,
    bookingChannel: null,
    appBuildConfig: null,
    checkState: null,
    customerForm: null,
  },
  isLoaderShown: true,
  onboardingApiServiceClient: null,
};

const getters = {
  remoteState: state => state.remoteState,
  isLoaderShown: state => state.isLoaderShown,
  onboardingApiServiceClient: (state, contextGetters, rootState, rootGetters) => ({ dispatch }) => {
    const authentication = Authentication({ dispatch, getters: rootGetters });
    // eslint-disable-next-line no-unused-vars
    const { authenticationFailureInterceptor, bearerTokenInterceptor } = authentication;
    if (state.onboardingApiServiceClient === null) {
      state.onboardingApiServiceClient = axios.create({
        baseURL: process.env.VUE_APP_ONBOARDING_SERVICE_BASE_URL,
        timeout: 30000,
      });
      state.onboardingApiServiceClient.interceptors.response.use(
        undefined, error => authenticationFailureInterceptor(error),
      );
      state.onboardingApiServiceClient.interceptors.request.use(
        config => bearerTokenInterceptor(config),
      );
    }
    return state.onboardingApiServiceClient;
  },
};

const actions = {
  async showLoader(context) {
    context.commit('showLoader');
  },
  async hideLoader(context) {
    context.commit('hideLoader');
  },
  async getAppConfig(context, { bookingChannelId }) {
    try {
      const { data: appConfig } = await context.getters.onboardingApiServiceClient(context)
        .get('/configurations/app-config', {
          params: {
            bookingChannelId,
          },
        });
      return appConfig;
    } catch (e) {
      if (e.response && e.response.data.errors) {
        const error = e.response.data.errors[0];
        if (error.code === 'CUSTOMER-CONFIGURATION-SERVICE-08') {
          return {};
        }
      }
      throw e;
    }
  },
  async updateAppConfig(context, { payload }) {
    context.commit('showLoader');

    try {
      const data = await context.getters.onboardingApiServiceClient(context)
        .put('/configurations/app-config', payload);

      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.appBuild.updated' },
        { root: true },
      );

      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.appBuild.failedToUpdate', error },
        { root: true },
      );

      throw error;
    } finally {
      context.commit('hideLoader');
    }
  },
  async updateBookingChannel(context, { id, payload }) {
    context.commit('showLoader');
    try {
      await context.dispatch('bookingChannels/updateBookingChannel', {
        id,
        payload: BookingChannelUtil.cleanBookingChannel(payload),
      }, { root: true });

      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'bookingChannel.updated' },
        { root: true },
      );
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.failedToUpdate', error },
        { root: true },
      );

      throw error;
    } finally {
      context.commit('hideLoader');
    }
    await context.dispatch('refreshRemoteState', { bookingChannelId: id });
  },
  async refreshRemoteState(context, { bookingChannelId }) {
    context.commit('showLoader');
    try {
      const { bookingChannel, etag: bookingChannelRevision } = await context.dispatch('bookingChannels/getBookingChannelById', bookingChannelId, { root: true });
      const checkState = await context.dispatch('onboarding/getCheckState', { humanReadableId: bookingChannel.humanReadableId }, { root: true });
      const appConfig = await context.dispatch('getAppConfig', { bookingChannelId });


      let customerForm = null;
      if (bookingChannel.customerFormId) {
        customerForm = await context.dispatch('customerData/getByIdMapped', bookingChannel.customerFormId, { root: true });
      }

      context.commit('setRemoteState', {
        bookingChannel, checkState, appConfig, bookingChannelRevision, customerForm,
      });
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'bookingChannel.failedToRefresh', error },
        { root: true },
      );
      throw error;
    } finally {
      context.commit('hideLoader');
    }
  },
  async onboardSystemComponents(context, { bookingChannelId }) {
    context.commit('showLoader');
    try {
      await context.dispatch('onboarding/onboardSystemComponents', {
        bookingChannelId,
      }, { root: true });
    } catch (e) {
      context.commit('hideLoader');
      throw e;
    }
    await context.dispatch('refreshRemoteState', { bookingChannelId });
  },
  /**
   * Get the legacy passenger app build configuration.
   * @param context
   * @param bookingChannelId
   * @returns {Promise<*>}
   */
  async getLegacyAppBuildConfig(context, { humanReadableId }) {
    try {
      const { data: appConfig } = await context.getters.onboardingApiServiceClient(context)
        .get('/configurations', {
          params: {
            humanReadableId,
          },
        });
      return appConfig;
    } catch (e) {
      if (e.response && e.response.data.errors) {
        const error = e.response.data.errors[0];
        if (error.code === 'CUSTOMER-CONFIGURATION-SERVICE-08') {
          return {};
        }
      }
      throw e;
    }
  },
  async getAppBuildConfig(context, { humanReadableId }) {
    try {
      const { data: appConfig } = await context.getters.onboardingApiServiceClient(context)
        .get('/configurations/app-build-config', {
          params: {
            humanReadableId,
          },
        });
      return appConfig;
    } catch (e) {
      if (e.response && e.response.data.errors) {
        const error = e.response.data.errors[0];
        if (error.code === 'CUSTOMER-CONFIGURATION-SERVICE-08') {
          return {};
        }
      }
      throw e;
    }
  },
  async onboardFirebaseAnalyticsConfig(context, { bookingChannelId, firebaseAnalyticsConfigPayload }) {
    context.commit('showLoader');

    try {
      const data = await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/firebase-analytics-config', {
          bookingChannelId,
          firebaseAnalyticsConfig: firebaseAnalyticsConfigPayload,
        });

      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.firebaseAnalyticsConfig.updatedSuccessMessage' },
        { root: true },
      );

      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.firebaseAnalyticsConfig.failedToUpdateMessage', error },
        { root: true },
      );

      throw error;
    } finally {
      await context.dispatch('refreshRemoteState', { bookingChannelId });
      context.commit('hideLoader');
    }
  },

  async onboardFirebaseWebbookerAnalytics(context, { bookingChannelId, firebaseWebbookerAnalyticsPayload }) {
    context.commit('showLoader');

    try {
      const data = await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/firebase-webbooker-analytics', {
          bookingChannelId,
          firebaseWebbookerAnalytics: firebaseWebbookerAnalyticsPayload,
        });

      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.firebaseWebbookerAnalytics.updatedSuccessMessage' },
        { root: true },
      );

      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.firebaseWebbookerAnalytics.failedToUpdateMessage', error },
        { root: true },
      );

      throw error;
    } finally {
      await context.dispatch('refreshRemoteState', { bookingChannelId });
      context.commit('hideLoader');
    }
  },
  async onboardIosAppStoreConfig(context, { bookingChannelId, iosAppStoreConfigPayload }) {
    context.commit('showLoader');

    try {
      const data = await context.getters.onboardingApiServiceClient(context)
        .post('/onboard/ios-app-store-config', {
          bookingChannelId,
          iosAppStoreConfig: _.omitBy(iosAppStoreConfigPayload, _.isNil),
        });

      context.dispatch(
        'globalMessages/addSuccess',
        { message: 'onboarding.iosAppStoreConfig.updatedSuccessMessage' },
        { root: true },
      );

      return data;
    } catch (error) {
      context.dispatch(
        'globalMessages/addError',
        { message: 'onboarding.iosAppStoreConfig.failedToUpdateMessage', error },
        { root: true },
      );

      throw error;
    } finally {
      await context.dispatch('refreshRemoteState', { bookingChannelId });
      context.commit('hideLoader');
    }
  },
};

const mutations = {
  setRemoteState(state, {
    bookingChannel, checkState, appConfig, bookingChannelRevision, customerForm,
  }) {
    state.remoteState = {
      bookingChannel,
      checkState,
      appConfig,
      bookingChannelRevision,
      customerForm,
    };
  },
  showLoader(state) {
    state.isLoaderShown = true;
  },
  hideLoader(state) {
    state.isLoaderShown = false;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
