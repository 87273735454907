<template>
  <div>
    <v-alert type="error" v-if="isDisplayFormError" class="form-error-alert">
      The form is invalid. Please correct the errors.
    </v-alert>

    <v-row class="pt-4">
      <v-col>
        <h4>{{ $t('onboarding.iosAppStoreConfig.formTitle') }}</h4>
      </v-col>
    </v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="8">
          <v-file-input
            v-model="p8CertificateFile"
            :label="$t(`onboarding.iosAppStoreConfig.p8Certificate`)"
            :placeholder="$t('onboarding.iosAppStoreConfig.p8Certificate')"
            outlined
            hide-details
            no-filter
          >
          </v-file-input>
          <span v-if="doesP8CertificateExist">
              {{ $t('onboarding.iosAppStoreConfig.existingP8CertificateNotification') }}
            </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="iosAppStoreConfig.issuerId"
            :label="$t(`onboarding.iosAppStoreConfig.issuerId`)"
            :placeholder="$t('onboarding.iosAppStoreConfig.issuerId')"
            outlined
            hide-details
            no-filter
            :validator="$v.iosAppStoreConfig.issuerId"
          >
          </v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model="iosAppStoreConfig.privateKeyId"
            :label="$t(`onboarding.iosAppStoreConfig.privateKeyId`)"
            :placeholder="$t('onboarding.iosAppStoreConfig.privateKeyId')"
            outlined
            hide-details
            no-filter
            :validator="$v.iosAppStoreConfig.privateKeyId"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-end">
            <v-btn class="mt-2" color="success" @click="onSaveClick" :disabled="!canSave">Save</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { checkStateDisplayMethods } from '@/mixins/onboardingStep.mixin';
import { decodeP8Certificate } from '@/services/cerficateService';
import { minLength, required } from 'vuelidate/lib/validators';
import _ from 'lodash';

export default {
  name: 'iosAppStoreConfigForm',
  mixins: [checkStateDisplayMethods],
  props: {
    remoteState: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.$v.$touch();
  },
  mounted() {
    if (_.get(this.bookingChannel, 'connectors.iosAppStoreConfig')) {
      this.iosAppStoreConfig.issuerId = this.bookingChannel.connectors.iosAppStoreConfig.issuerId;
      this.iosAppStoreConfig.privateKeyId = this.bookingChannel.connectors.iosAppStoreConfig.privateKeyId;
    }
  },
  data() {
    return {
      bookingChannel: _.cloneDeep(this.remoteState.bookingChannel),
      p8CertificateFile: null,
      isDisplayFormError: null,
      iosAppStoreConfig: {
        p8Certificate: null,
        issuerId: null,
        privateKeyId: null,
      },
    };
  },
  computed: {
    canSave() {
      return this.$v.$anyDirty && !this.$v.$anyError;
    },
    doesP8CertificateExist() {
      return _.get(this.bookingChannel, 'connectors.iosAppStoreConfig.secretName') !== null;
    },
  },
  methods: {
    async onSaveClick() {
      this.isDisplayFormError = false;
      this.$v.$touch();
      if (this.hasErrors()) {
        this.isDisplayFormError = true;
      } else {
        await this.save();
      }
    },
    hasErrors() {
      return this.$v.$invalid;
    },
    async save() {
      if (this.p8CertificateFile) {
        this.iosAppStoreConfig.p8Certificate = await decodeP8Certificate(
          this.p8CertificateFile,
        );
      } else {
        this.iosAppStoreConfig.p8Certificate = null;
      }

      await this.$store.dispatch('bookingChannelView/onboardIosAppStoreConfig', {
        bookingChannelId: this.bookingChannel.id,
        iosAppStoreConfigPayload: this.iosAppStoreConfig,
      });
    },
  },
  validations() {
    return {
      iosAppStoreConfig: {
        issuerId: {
          minLength: minLength(1),
          required,
        },
        privateKeyId: {
          minLength: minLength(1),
          required,
        },
      },
    };
  },
};
</script>

<style scoped>

</style>
