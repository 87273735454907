<template>
  <v-container>
    <NetworkFleetsList
      @loadData="getNetworkFleets"
      :is-loading="networkFleetsLoading"
      :items="networkFleets"
      @viewFleetClick="showFleetManagement($event)"
      @addFleetClick="displayDialog = true"
      @manageNetworkFleets="manageNetworkFleets($event)"
    >
    </NetworkFleetsList>
    <NetworkFleetDialog
      @loadData="getFleets"
      :fleets="fleets"
      :display="displayDialog"
      :isFleetSearchLoading="fleetsLoading"
      :totalFleets="totalFleets"
      @onDismiss="closeNetworkFleetDialog"
      @manageNetworkFleets="manageNetworkFleets($event)"
    >
    </NetworkFleetDialog>
  </v-container>
</template>
<script>
import NetworkFleetsList from '@/components/network/NetworkFleetsList.vue';
import NetworkFleetDialog from '@/components/network/NetworkFleetDialog.vue';

export default {
  name: 'network-fleets-view',
  data() {
    return {
      selectedNetwork: null,
      emptyNetwork: {
        name: null,
      },
      networkFleets: [],
      fleets: [],
      totalFleets: 0,
      fleetsLoading: false,
      networkFleetsLoading: false,
      displayDialog: false,
    };
  },
  components: {
    NetworkFleetsList,
    NetworkFleetDialog,
  },
  methods: {
    async viewFleetList(network) {
      await this.$router.push(`/networks/fleets/${network.id}`);
    },
    async showFleetManagement(fleet) {
      await this.$router.push(`/fleets/${fleet.id}`);
    },
    async getNetworkFleets({
      networkId, page, maxPerPage, dynamicSearch,
    }) {
      this.networkFleetsLoading = true;
      const fleets = await this.$store.dispatch('fleetNetworkApi/getNetworkFleets', {
        networkId, page, maxPerPage, dynamicSearch,
      });
      this.networkFleets = fleets;
      this.networkFleetsLoading = false;
    },
    async getFleets({
      dynamicSearch,
      page,
      maxPerPage,
    }) {
      this.fleetsLoading = true;
      const network = await this.$store.dispatch('fleetNetworkApi/getFleetNetworkById', {
        id: this.$route.params.id,
      });
      if (!network) {
        return;
      }
      const { fleets, totalFleets } = await this.$store.dispatch('fleets/getFleets', {
        page,
        maxPerPage,
        dynamicSearch,
      });
      this.fleets = fleets;
      this.totalFleets = totalFleets;
      this.fleetsLoading = false;
    },
    closeNetworkFleetDialog() {
      this.displayDialog = false;
    },
    async manageNetworkFleets({ removedFleets, addedFleets }) {
      const networkId = this.$route.params.id;
      await this.$store.dispatch('fleetNetworkApi/manageNetworkFleets', { removedFleets, addedFleets, networkId });
      const params = {
        networkId: this.$route.params.id,
        page: 0,
        maxPerPage: 5,
      };
      await this.getNetworkFleets(params);
      this.displayDialog = false;
    },
  },
};
</script>

<style scoped>

</style>
